define("m08-2020/components/masskett-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'masskett-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    translation: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.masskettenHelper', function () {
      var x = 0;
      var y = 0;
      var z = 0;
      var cords = x + ' ' + y + ' ' + z;
      return cords;
    }),
    rotation: Ember.computed('model.firstObject.transformHelper', function () {
      var x = 0;
      var y = 0;
      var z = 0;
      var winkel = 0;
      var values = x + ' ' + y + ' ' + z + ' ' + winkel;
      return values;
    }),
    koordinaten: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.masskettenHelper', 'model.firstObject.viewpointHelper', function () {
      var name = this.get('name'); // console.log("name: "+name);

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var hwinkel = Number(x3d.get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;
      var x = 0;
      var y = 0;
      var z = 0;
      var cords;
      var p1 = new Object();
      p1.y = 0;
      p1.z = 0;
      var p2 = new Object();
      p2.y = 0;
      p2.z = 0;
      var drehwinkel = new Object();
      drehwinkel.x = 0;
      drehwinkel.y = 0;
      drehwinkel.z = 0;
      var w90Grad = 90 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;
      var item = x3d.get('bauteile').findBy('id', name);
      var ueberstand = Number(this.get('ueberstand'));
      p1.x = item.get('punkt').objectAt(1).get('x');
      p1.y = item.get('punkt').objectAt(1).get('y');
      p1.z = item.get('punkt').objectAt(1).get('z');
      p2.x = item.get('punkt').objectAt(0).get('x');
      p2.y = item.get('punkt').objectAt(0).get('y');
      p2.z = item.get('punkt').objectAt(0).get('z');
      var richtungsvektor1 = new Object();
      var richtungsvektor2 = new Object(); // console.log('p1');
      // console.log(p1);
      // console.log('p2');
      // console.log(p2);
      //
      // console.log('Richtungsvektor x: '+Number(item.get('richtungsvektor').objectAt(0).get('x'))+' y: '+Number(item.get('richtungsvektor').objectAt(0).get('y'))+' z: '+Number(item.get('richtungsvektor').objectAt(0).get('z')));

      var sichtEbene = "AnsichtXY";

      if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
        sichtEbene = "AnsichtXZ";
      } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
        sichtEbene = "AnsichtYZ";
      } // console.log(item.id);
      // console.log(item.id.length);
      // console.log(item.id.substring(0, item.id.length-3));


      sichtEbene = 'masskette' + sichtEbene + item.id.substring(0, item.id.length - 3);
      richtungsvektor1.x = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('x'));
      richtungsvektor1.y = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('y'));
      richtungsvektor1.z = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('z'));
      richtungsvektor2 = richtungsvektor1;
      var ebene = Number(item.get('masskette').objectAt(0).get('ebene')); // console.log(p1);
      // console.log(p2);
      // console.log(richtungsvektor1);
      // console.log(richtungsvektor2);
      // console.log(ebene);

      cords = this.getVektorKoordinaten(p1, p2, richtungsvektor1, richtungsvektor2, ebene); // console.log(cords);

      return cords;
    }),
    koordinatenIndex: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.masskettenHelper', function () {
      var values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
      return values;
    }),
    emissivecolor: Ember.computed('model.firstObject.istAktivHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', this.get('name'));
      var emsvcolor = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      return emsvcolor;
    }),
    getVektorKoordinaten: function (punkt1, punkt2, r1, r2, ebene) {
      var dr = 2 * (ebene - 1);
      var b05 = 0.5;
      var b1 = 1;
      var b5 = 5;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 6;
      var verschiebung = b5 * (ebene - 1) + dr;
      var p0 = Number(punkt1.x) + verschiebung * Number(r1.x) + ' ' + (Number(punkt1.y) + verschiebung * Number(r1.y)) + ' ' + (Number(punkt1.z) + verschiebung * Number(r1.z));
      var p1 = Number(punkt2.x) + verschiebung * Number(r2.x) + ' ' + (Number(punkt2.y) + verschiebung * Number(r2.y)) + ' ' + (Number(punkt2.z) + verschiebung * Number(r2.z));
      var p2 = Number(punkt2.x) + (b6 + verschiebung) * Number(r2.x) + ' ' + (Number(punkt2.y) + (b6 + verschiebung) * Number(r2.y)) + ' ' + (Number(punkt2.z) + (b6 + verschiebung) * Number(r2.z));
      var p3 = Number(punkt1.x) + (b6 + verschiebung) * Number(r1.x) + ' ' + (Number(punkt1.y) + (b6 + verschiebung) * Number(r1.y)) + ' ' + (Number(punkt1.z) + (b6 + verschiebung) * Number(r1.z));
      var p4 = this.getXYZ(punkt1, punkt2, r1, r2, b5 + verschiebung, b1);
      var p5 = this.getXYZ(punkt2, punkt1, r2, r1, b5 + verschiebung, b1);
      var p6 = this.getXYZ(punkt2, punkt1, r2, r1, b45 + verschiebung, b05);
      var p8 = this.getXYZ(punkt1, punkt2, r1, r2, b45 + verschiebung, -b05);
      var p7 = this.getXYZ(punkt2, punkt1, r2, r1, b55 + verschiebung, -b05);
      var p9 = this.getXYZ(punkt1, punkt2, r1, r2, b55 + verschiebung, b05);
      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;
      return koordinaten;
    },
    vektorLaenge: function (x, y, z) {
      return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
    },
    getXYZ: function (punkt1, punkt2, r1, r2, richtungsAbstand, seitenAbstand) {
      var p1_2 = {
        x: Number(punkt1.x) + richtungsAbstand * Number(r1.x),
        y: Number(punkt1.y) + richtungsAbstand * Number(r1.y),
        z: Number(punkt1.z) + richtungsAbstand * Number(r1.z)
      };
      var p2_2 = {
        x: Number(punkt2.x) + richtungsAbstand * Number(r2.x),
        y: Number(punkt2.y) + richtungsAbstand * Number(r2.y),
        z: Number(punkt2.z) + richtungsAbstand * Number(r2.z)
      };
      var r3 = {
        x: p2_2.x - p1_2.x,
        y: p2_2.y - p1_2.y,
        z: p2_2.z - p1_2.z
      };
      var lr3 = this.vektorLaenge(r3.x, r3.y, r3.z);
      lr3 = lr3 === 0 ? 1 : lr3;
      var r3Einheitsvektor = {
        x: r3.x / lr3,
        y: r3.y / lr3,
        z: r3.z / lr3
      };
      var x = p1_2.x + r3Einheitsvektor.x * (lr3 + seitenAbstand);
      var y = p1_2.y + r3Einheitsvektor.y * (lr3 + seitenAbstand);
      var z = p1_2.z + r3Einheitsvektor.z * (lr3 + seitenAbstand);
      return x + ' ' + y + ' ' + z;
    },
    drehungXAchse: function (v, omega) {
      var r = new Object();
      r.x = v.x;
      r.y = v.y * Math.cos(omega) - v.z * Math.sin(omega);
      r.z = v.y * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },
    drehungYAchse: function (v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) + v.z * Math.sin(omega);
      r.y = v.y;
      r.z = -v.x * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },
    drehungZAchse: function (v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) - v.y * Math.sin(omega);
      r.y = v.x * Math.sin(omega) + v.y * Math.cos(omega);
      r.z = v.z;
      return r;
    },
    getMittelwert: function (a, b) {
      return (a + b) / 2;
    },
    postionDurchSkalierung: function (richtung) {
      var x3d = this.get('model').objectAt(0);
      var skalierungsfaktor = x3d.get('skalierungsfaktor') + 0.6;
      var value = 0.2 * skalierungsfaktor;
      return value;
    }
  });
  var _default = Transform;
  _exports.default = _default;
});