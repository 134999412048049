define("m08-2020/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    beforeModel() {
      this.intl.setLocale(['de']);
    },

    model: function () {
      var self = this;
      return Ember.RSVP.hash({
        application: self.store.findAll('application'),
        projektdaten: self.store.findAll('projektdaten'),
        geometrie: self.store.findAll('geometrie'),
        optionen: self.store.findAll('optionen'),
        lasteinwirkung: self.store.findAll('lasteinwirkung'),
        x3ddefault: self.store.findAll('x3ddefault')
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.initialisierung(); // controller.set('model', model);

      this.watchingTextSize(controller);
    },

    watchingTextSize: function (controller) {
      var self = this;
      var viewingRay;
      var distance;
      Ember.run.debounce(function () {
        console.log('Seite gerendert?');

        try {
          document.getElementById('x3d').runtime.enterFrame = function () {
            var skalfak = controller.model.x3ddefault.objectAt(0).get('skalierungsfaktor');
            var vp = controller.model.x3ddefault.objectAt(0).get('lastViewpoint'); // distance = Number(controller.model.x3ddefault.objectAt(0).get('aktuelleDistance'));
            // var _11 = document.getElementById('x3d').runtime.projectionMatrix()._11;
            // skalfak = (distance/_11)/50;

            skalfak = Math.abs(document.getElementById(vp).getFieldValue('fieldOfView')[0]) / 4; // if (skalfak < 0.75){skalfak=0.75;}
            //begrenzt den Skalierungsfaktor, sodass nicht mehr als auf die dreifache größe skaliert wird
            // if (skalfak > 3){skalfak=3;}

            controller.model.x3ddefault.objectAt(0).set('skalierungsfaktor', skalfak); // var texts = document.getElementsByTagName('text');
            // var str = '';
            // for (var j = 0; j < texts.length; j++) {
            //   str = texts[j].getAttribute('string');
            //   texts[j].setAttribute('string', ' ');
            //   texts[j].setAttribute('string', str);
            // }
          };

          $(document).ready(function () {
            setX3DSize();
          });
          $(window).resize(function () {
            setX3DSize();
          });

          function setX3DSize() {
            var x3dGrafik = document.getElementById("threedee");
            var x3d = document.getElementById("x3d");
            var canvas = document.getElementsByTagName("canvas");
            var x3druntime = document.getElementById('x3d').runtime; // canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);

            x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20); // canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);

            x3d.setAttribute('height', Number($(window).height()) - 300);
          }
        } catch (error) {
          console.log('/routes/application.js: x3dom need reload');
          x3dom.reload();
          self.watchingTextSize(controller);
        }
      }, 500);
    }
  });

  _exports.default = _default;
});